<template>
  <el-container class="bodybox">
    <el-header>
      <Header @change='change' />
    </el-header>

    <el-main class="mainbox">
      <router-view v-if="show" />
    </el-main>
  </el-container>
</template>
<script>
  import BUS from '@/utils/bus.js'
  import Header from "../components/header"
  export default {
    components: { Header },
    data() {
      return {
        show: true,
      }
    },
    computed: {},
    watch: {},
    methods: {
      change() {
        this.show = false;
        setTimeout(() => {
          this.show = true;
        }, 50);
      }
    },
    created() {
      BUS.$on('change_api', () => {
        this.show = false;
        setTimeout(() => {
          this.show = true;
        }, 50);
      })
    },
    mounted() { },
    beforeCreate() { }, //生命周期 - 创建之前
    beforeMount() { }, //生命周期 - 挂载之前
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
  }
</script>
<style lang="scss" scoped>
  .bodybox {
    width: 100%;
    min-height: 100vh;
    background: #f0f2f4;
  }
  /deep/.el-header {
    width: 100%;
    min-height: 65px !important;
    height: auto !important;
    background: #ffffff;
    // border-bottom: 1px solid #babdcd;
  }
  /deep/.el-main {
    width: 100%;
    height: 90%;
    padding: 0;
  }
</style>>