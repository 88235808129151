<template>
  <div class="headerbox" v-loading='loading'>
    <img src="../assets/img/logo.png" alt="" />
    <div class="center" v-if="show">
      <el-menu :default-active="activeIndex" router mode="horizontal" class="el-menu-demo" active-text-color="#19CBBF"
        @select="handleSelect">
        <template v-for="(item, index) in topMenus">
          <template v-if="Number(index < visibleNumber)">
            <template v-if="!item.children">
              <el-menu-item :index="item.path" :key="index">
                {{ item.name }}
              </el-menu-item>
            </template>
            <template v-else>
              <el-submenu :index="item.path" :key="index">
                <template slot="title">{{ item.name }}</template>
                <el-menu-item :index="val.path" v-for="val in item.children" :key="val.path">{{ val.name }}</el-menu-item>
              </el-submenu>
            </template>
          </template>
        </template>
        <el-submenu index="more" v-if="topMenus.length > visibleNumber">
          <template slot="title">More menus</template>
          <template v-for="(item, index) in topMenus">
            <template v-if="index >= visibleNumber">
              <template v-if="!item.children">
                <el-menu-item :index="item.path" :key="item.name">{{ item.name }}</el-menu-item>
              </template>
              <template v-if="item.children">
                <el-submenu :index="item.path" :key="index">
                  <template slot="title">{{ item.name }}</template>
                  <el-menu-item :index="val.path" v-for="val in item.children" :key="val.path">{{ val.name
                  }}</el-menu-item>
                </el-submenu>
              </template>
            </template>
          </template>
        </el-submenu>
      </el-menu>
    </div>
    <div class="right">
      <el-select v-model="api_id" size="small" placeholder="Please select" filterable remote @change="change">
        <el-option v-for="item in options" :key="item.id" :label="item.company" :value="item.id">
        </el-option>
      </el-select>
    </div>
  </div>
</template>
<script>
import BUS from '@/utils/bus.js'
export default {
  data() {
    return {
      topMenus: [
        {
          name: 'DEAR Assembly',
          path: 'assembly',
          children: [
            {
              name: 'Order Generation',
              path: '/',
            },
            {
              name: 'Order List',
              path: 'list',
            },
          ]
        },
        {
          name: 'DEAR Digital',
          path: 'digital',
        },
        {
          name: 'DEAR Transfer',
          path: 'transfer',
          children: [
            {
              name: 'Order List',
              path: 'transferOrder',
            },
            {
              name: 'Order Import',
              path: 'transfer',
            },
          ]
        },
        {
          name: 'Settings',
          path: 'set',
          children: [
            {
              name: 'User',
              path: 'user',
            },
            {
              name: 'DEAR API',
              path: 'api',
            },
            {
              name: 'System Setting',
              path: 'setting',
            },
            {
              name: 'System Log',
              path: 'logList',
            },
            {
              name: 'Jira Field',
              path: 'formList',
            },
            {
              name: 'JSON Conversion',
              path: 'formImport'
            }
          ]
        }
      ],


      visibleNumber: 4,
      activeIndex: "/",
      api: "",
      rule_names: [],
      options: [],
      getRules: {},
      show: true,
      loading: false,
    };
  },
  computed: {
    api_id: {
      get() {
        return this.$store.state.user.api_id;
      },
      set(data) {
        this.$store.dispatch("change_API_ID", data);
      },
    },
  },
  watch: {
    api_id: {
      handler(data) {
        this.getinfo(data)
      },
      deep: true,
      immediate: true
    },
    $route(route) {
      // this.activeIndex = route.name
      if (route.name == 'transfer') {
        this.activeIndex = route.name
      }
    }
  },
  methods: {
    getlist() {
      this.$store.dispatch("change_API_ID", '');
      this.$post({
        url: "/app/member_api/apiList",
        data: {
          page: 1,
          page_size: 1000,
        },
        success: (res) => {
          this.options = res.data.data;
          if (this.options.length && !this.api_id) {
            this.api_id = this.options[0].id;
            this.$store.dispatch("change_API_ID", this.api_id);
          }
        },
        tip: () => { },
      });
    },
    handleSelect(key) {
      sessionStorage.setItem("activeIndex", key);
    },
    getinfo(api_id) {
      this.$store.dispatch("set_ROLES", []);
      if (!api_id) return;
      this.$post({
        url: "/app/member/getRules",
        data: { api_id },
        success: (res) => {
          this.loading = true
          this.show = false
          this.getRules = res.data;
          this.rule_names = res.data.rule_names;
          setTimeout(() => {
            this.$store.dispatch("set_ROLES", res.data.rule_names);
            this.$emit("change");
            this.show = true
            this.loading = false
          }, 10);
        },
        tip: () => { },
      });
    },
    change() {
      this.$emit("change");
    },
    // 根据宽度计算设置显示栏数
    setVisibleNumber() {
      const width = document.body.clientWidth;
      if (width > 1100) {
        this.visibleNumber = 4
      } else if (710 < width && width < 1099) {
        this.visibleNumber = 1
      } else if (700 > width) {
        this.visibleNumber = 0
      }
    },
  },
  created() {
    this.getlist();
    if (sessionStorage.getItem("activeIndex")) {
      this.activeIndex = sessionStorage.getItem("activeIndex");
      this.$router.push(this.activeIndex)
    } else {
      this.activeIndex = '/'
      this.$router.push('/')
    }

    BUS.$on('apilist', () => {
      this.getlist();
      this.activeIndex = sessionStorage.getItem("activeIndex");
    })
  },
  mounted() {
    this.setVisibleNumber()
  },
  beforeMount() {
    window.addEventListener('resize', this.setVisibleNumber)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setVisibleNumber)
  },
};
</script>
<style lang="scss" scoped>
.headerbox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: auto;
  >img {
    width: 100px;
    height: 40px;
    object-fit: cover;
  }
  .center {
    flex: 1;
    padding: 0 30px;
    /deep/.el-menu-demo {
      flex: 1;
      // overflow-y: auto;
      .el-menu-item {
        padding: 0 7px;
      }
    }
  }
  .right {
    width: 16%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    >img {
      width: 25px;
      height: 25px;
      margin-left: 40px;
    }
  }
}
</style>
